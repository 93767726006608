import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const bijoux = () => (
  <Layout>
    <SEO title="Bijoux" />

    <div className="slideshow">
      <div className="usp">
        <span className="usp-txt-header">Bijouterie</span>
      </div>
    </div>
    <div className="border-gold-bottom"></div>



    <h2 className="h2-mobile"  style={{ marginTop: 40 }}>Bijouterie</h2>

    <section className="section-flex-04">
    <p className="p-2 p-font" style={{textAlign: 'center'}}>Voici quelques exemples de créations élaborées par nos soins.</p>

      <div className="section-flex-03">

          <div className="gold-box-small">
            <Link to="/bagues" className="gold-box-small-text" style={{textDecoration:'none'}}>
              <div className="gold-box-small-img-Bagues">Bagues</div>
            </Link>
          </div>

          <div className="gold-box-small">
            <Link to="/alliances" className="gold-box-small-text" style={{textDecoration:'none'}}>
              <div className="gold-box-small-img-Bagues">Alliances</div>
            </Link>
          </div>

          <div className="gold-box-small">
            <Link to="/solitaires" className="gold-box-small-text" style={{textDecoration:'none'}}>
              <div className="gold-box-small-img-Bagues">Solitaires</div>
            </Link>
          </div>

      </div>

      <div className="section-flex-03">

      <div className="gold-box-small">
        <Link to="/colliers_pendentifs" className="gold-box-small-text" style={{textDecoration:'none'}}>
          <div className="gold-box-small-img-Bagues">Colliers & Pendentifs</div>
        </Link>
      </div>

      <div className="gold-box-small">
        <Link to="/boucles_d_oreilles" className="gold-box-small-text" style={{textDecoration:'none'}}>
          <div className="gold-box-small-img-Bagues">Boucles D'Oreilles</div>
        </Link>
      </div>

      <div className="gold-box-small">
        <Link to="/perles_pierres" className="gold-box-small-text" style={{textDecoration:'none'}}>
          <div className="gold-box-small-img-Bagues">Perles & Pierres</div>
        </Link>
      </div>

      </div>

    </section>


    <h3>Notre savoir faire</h3>

    <section className="section-flex-04">

      <div className="section-flex-03">

        <div className="gold-box-small">
          <Link to="/creation" className="gold-box-small-text" style={{textDecoration:'none'}}>
            <div className="gold-box-small-img-Bagues">Création sur mesure</div>
          </Link>
        </div>

        <div className="gold-box-small">
          <Link to="/transformation" className="gold-box-small-text" style={{textDecoration:'none'}}>
            <div className="gold-box-small-img-Bagues">Transformation</div>
          </Link>
        </div>

        <div className="gold-box-small">
          <Link to="/expertises" className="gold-box-small-text" style={{textDecoration:'none'}}>
            <div className="gold-box-small-img-Bagues">Estimations et Expertises</div>
          </Link>
        </div>

      </div>

      <div className="section-flex-03">

        <div className="gold-box-small">
          <Link to="/restauration" className="gold-box-small-text" style={{textDecoration:'none'}}>
            <div className="gold-box-small-img-Bagues">Restauration</div>
          </Link>
        </div>

        <div className="gold-box-small">
          <Link to="/sertissage" className="gold-box-small-text" style={{textDecoration:'none'}}>
            <div className="gold-box-small-img-Bagues">Sertissage</div>
          </Link>
        </div>

        <div className="gold-box-small">
          <Link to="/gravures" className="gold-box-small-text" style={{textDecoration:'none'}}>
            <div className="gold-box-small-img-Bagues">Gravures</div>
          </Link>
        </div>

      </div>

      <div className="section-flex-03">

        {/*<div className="gold-box-small">
          <div className="gold-box-small-img-Bagues">
            <Link to="/horlogerie" className="gold-box-small-text">Horlogerie</Link>
          </div>
        </div>*/}

        <div className="gold-box-small">
          <Link to="/renfilage" className="gold-box-small-text" style={{textDecoration:'none'}}>
            <div className="gold-box-small-img-Bagues">Renfilage de Colliers</div>
          </Link>
        </div>

        <div className="gold-box-small">
          <Link to="/achat_vieil_or" className="gold-box-small-text" style={{textDecoration:'none'}}>
            <div className="gold-box-small-img-Bagues">Achat vieil Or</div>
          </Link>
        </div>

      </div>

    </section>

  </Layout>
)

export default bijoux
